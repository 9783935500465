import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as PropTypes from 'prop-types'

import Button from 'src/UIComponents/Button/ButtonDefault'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import CreateTransferComponentForm from './CreateTransferComponentForm'

import { resetCreateTransfer } from '../../Actions/transfers'
import { resetRecipientMeans } from '../../Actions/recipientMeans'
import { CreateTransferContextProvider } from '../../Context/Transfer/CreateTransferContext'
import { Beacon } from '../OnboardingHints/Beacon'
import { getApiSettings } from '../../Actions/settings'

const CreateTransferComponent = ({ buttonInversed }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const match = useRouteMatch({
    path: '*/fazer-transferencia'
  })

  const [showSidebar, setShowSidebar] = useState(!!match)

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        setShowSidebar(true)
      }, 500)
    }
  }, [])

  const handleClose = () => {
    setShowSidebar(false)
    setTimeout(() => {
      history.push(match.params[0])
      dispatch(resetCreateTransfer())
      dispatch(resetRecipientMeans())
    }, 500)
  }

  const handleClick = () => {
    dispatch(getApiSettings(true))
    history.push(`${location.pathname}/fazer-transferencia`)
    setTimeout(() => {
      setShowSidebar(true)
    }, 500)
  }

  return (
    <CreateTransferContextProvider>
      <Button ghost={buttonInversed} outline={buttonInversed} widthAuto onClick={handleClick}>Fazer transferência</Button>
      <Beacon hint='fazer-transferencia' wrapperProps={{ right: '80px', top: '30px' }} />
      {
        match && <Sidebar show={showSidebar}>
          <CreateTransferComponentForm handleClose={handleClose} />
        </Sidebar>
      }
    </CreateTransferContextProvider>
  )
}

CreateTransferComponent.propTypes = {
  buttonInversed: PropTypes.bool
}

export default CreateTransferComponent
