import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import FormZ from '../../UIComponents/FormZ/FormZ'
// import Grid from '../../UIComponents/Grid/Grid'
import Grid from '../../UIComponents/Grid/Grid'
import { getFormOptionsWithValidation } from '../../Utils/FormUtils'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'

const lastStepStruct = t.struct({
  description: t.maybe(t.String)
})

const LastStepForm = ({ options, values, handleChange, showFormValidation, error }) => {
  useEffect(() => {
    handleChange({ password: null }, 'lastStepValues')
  }, [])

  return (
    <>
      <Grid.Row auto noMargin>
        <Grid.Col cols={16}>
          <FormZ
            options={showFormValidation
              ? getFormOptionsWithValidation(options, values, lastStepStruct)
              : options}
            struct={lastStepStruct}
            values={values}
            onChange={(values) => handleChange(values, 'lastStepValues')}
            isRequesting={false}
          />
        </Grid.Col>
      </Grid.Row>
      {error && <Grid.Row auto bigMarginBottom>
        <AlertMessage type='error' error={error} />
      </Grid.Row>}
    </>
  )
}

LastStepForm.struct = lastStepStruct

LastStepForm.propTypes = {
  options: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  showFormValidation: PropTypes.bool
}

export default LastStepForm
