import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import UIVisualIdentity from 'src/UIComponents/Customize/VisualIdentity'

const VisualIdentity = ({ hideButtonEdit, handleEdit }) => {
  const { custom } = useSelector(state => ({
    custom: state.custom
  }))

  return (
    <UIVisualIdentity
      custom={custom}
      handleEdit={handleEdit}
      hideButtonEdit={hideButtonEdit}
    />
  )
}

VisualIdentity.propTypes = {
  handleEdit: PropTypes.func,
  hideButtonEdit: PropTypes.bool
}

export default VisualIdentity
