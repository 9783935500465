import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { debounce, mapValues } from 'lodash'

import formValidators from '../../Utils/formValidators'
import FormZ from '../../UIComponents/FormZ/FormZ'
import { getSellerByDocument } from '../../Actions/seller'
import Grid from '../../UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import CreateTransferContext from '../../Context/Transfer/CreateTransferContext'
import styled from 'styled-components'
import CheckboxSingle from '../../UIComponents/Checkbox/CheckboxSingle'

const p2pStruct = t.struct({
  document: formValidators.Document
})

const P2PForm = ({
  options,
  values,
  handleChange,
  showFormValidation,
  setLoading
}) => {
  const [showSellerValidation, setShowSellerValidation] = useState(false)
  const {
    state: { receiver },
    setState: setCreateTransferState
  } = useContext(CreateTransferContext)
  const [possibleSellers, setPossibleSellers] = useState([])
  const [selectSeller, setSelectSeller] = useState(null)

  useEffect(() => {
    return () => {
      options.fields = {
        ...options.fields,
        document: {
          ...options.fields.document,
          hasError: false
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!receiver) {
      innerOnChange({ document: null })
    }
  }, [receiver])

  useEffect(() => {
    if (!selectSeller) {
      setCreateTransferState({ receiver: null })
      setShowSellerValidation(true)
    }

    if (selectSeller) {
      setCreateTransferState({
        receiver: selectSeller
      })
      handleChange(values, 'p2pValues')
    }
  }, [selectSeller])

  const getFieldValidation = (fieldName) => {
    if (!values[fieldName])
      return { message: 'Você esqueceu de preencher aqui?' }

    return t.validate(values, p2pStruct).errors.find((error) => {
      return error.path.includes(fieldName)
    })
  }

  const innerOnChange = async (values) => {
    setLoading(true)
    handleChange(values, 'p2pValues')
    setShowSellerValidation(false)

    const document = values.document
    if (document && (document.length === 11 || document.length === 14)) {
      const params = {
        [values.document.length === 11 ? 'taxpayer_id' : 'ein']: values.document
      }
      const sellerInfo = await getSellerByDocument(params)
      if (!sellerInfo) {
        setPossibleSellers([])
        setCreateTransferState({ receiver: null })
        setShowSellerValidation(true)
      } else {
        setShowSellerValidation(false)
        setPossibleSellers(sellerInfo)
      }
    }
    setLoading(false)
  }

  if (showFormValidation || showSellerValidation) {
    options.fields = mapValues(options.fields, (field, key) => {
      const fieldValidation = getFieldValidation(key)

      if (key === 'document') {
        if (!fieldValidation && showSellerValidation) {
          return {
            ...field,
            hasError: true,
            error: (
              <>
                <strong>Não foi possível encontrar essa conta digital</strong>
              </>
            )
          }
        }
      }

      return {
        ...field,
        hasError: fieldValidation && fieldValidation.hasOwnProperty('message'),
        error: fieldValidation && fieldValidation.message
      }
    })
  }

  return (
    <>
      <Grid.Row auto bigMarginBottom>
        <Grid.Col cols={16}>
          {possibleSellers?.length === 0 ? (
            <FormZ
              options={options}
              struct={p2pStruct}
              values={values}
              onChange={debounce(innerOnChange, 500)}
              isRequesting={false}
            />
          ) : (
            possibleSellers.map((item) => (
              <CardContainer
                key={item.id}
                onClick={() => {
                  setSelectSeller(item)
                }}
              >
                <div className='flex__align__center'>
                  <CheckboxSingle checked={item.id === selectSeller?.id} />
                </div>
                <InfoContainer className='info__container'>
                  <div>
                    <Label
                      className='mr-1'
                      color={darkGray}
                      textAlign='left'
                      bold
                    >
                      Transferir para
                    </Label>
                    <Label color={darkerGray} textAlign='left'>
                      {item?.taxpayer_id ? item.name : item.business_name}
                    </Label>
                  </div>
                  <div>
                    <Label
                      className='mr-1'
                      color={darkGray}
                      textAlign='left'
                      bold
                    >
                      {item?.taxpayer_id ? 'CPF' : 'CNPJ'}
                    </Label>
                    <Label color={darkerGray} textAlign='left'>
                      {(item?.taxpayer_id
                        ? item.taxpayer_id
                        : item.ein
                      )?.toDocument()}
                    </Label>
                  </div>
                  <div>
                    <Label
                      className='mr-1'
                      color={darkGray}
                      textAlign='left'
                      bold
                    >
                      Email
                    </Label>
                    <Label color={darkerGray} textAlign='left'>
                      {item.email || item.business_email}
                    </Label>
                  </div>
                </InfoContainer>
              </CardContainer>
            ))
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

P2PForm.struct = p2pStruct

P2PForm.propTypes = {
  options: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  showFormValidation: PropTypes.bool
}

const CardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin: 0;
  box-shadow: 0px 3px 7px #ebebeb;

  display: flex;
  gap: 2rem;
  margin-top: 3rem !important;

  .flex__align__center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .mr-1 {
    margin-right: 2rem !important;
  }
  .flex__card {
    display: flex;
  }
`
export default P2PForm
