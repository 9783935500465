import React, { useContext } from 'react'

import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import SuccessCard from 'src/UIComponents/SuccessCard/SuccessCard'
import Title from 'src/UIComponents/Title/Title'

import TransferResume from 'src/UIComponents/TransferDetail/Receipt/TransferResume'
import TransferSenderInfo from 'src/UIComponents/TransferDetail/Receipt/TransferSenderInfo'
import TransferReceiverInfo from 'src/UIComponents/TransferDetail/Receipt/TransferReceiverInfo'

import { parseDate } from 'src/Utils/Utils'

import Grid from 'src/UIComponents/Grid/Grid'
import { darkGray, bigText } from 'src/Styles/settings/Constants'
import CreateTransferContext from '../../Context/Transfer/CreateTransferContext'

const SuccessTransferComponent = ({ transfer, sender, handleClose }) => {
  const { state: { receiver } } = useContext(CreateTransferContext)

  const subtitle = () => {
    return parseDate(transfer.created_at, 'DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')
  }

  return (
    <>
      <HeaderSidebar
        title={<><strong>Comprovante </strong>da Transferência</>}
        subTitle={subtitle()}
        handleClose={handleClose}
      />
      <SidebarContainer>
        <br />
        <br />
        <Grid.Row auto bigMarginBottom>
          <SuccessCard
            noPadding
            sizeButton='192px'
          >
            <Title noPadding noTransform color={darkGray} fontSize={bigText}><strong>Perfeito! </strong>Transferência <br />efetuada com sucesso</Title>
          </SuccessCard>
        </Grid.Row>
        <Grid.Row auto bigMarginBottom>
          <TransferResume transfer={transfer} sender={sender} />
        </Grid.Row>

        <Grid.Row auto bigMarginBottom>
          <TransferSenderInfo sender={sender} />
        </Grid.Row>

        <Grid.Row auto bigMarginBottom>
          <TransferReceiverInfo receiver={receiver} transfer={transfer} />
        </Grid.Row>
      </SidebarContainer>
    </>
  )
}

export default SuccessTransferComponent
