import React, { Fragment, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { isEqual } from 'lodash'

import UIDashboardURL from 'src/UIComponents/Customize/DashboardURL'
import * as customActions from 'src/Actions/custom'
import formValidators from 'src/Utils/formValidators'
import Disclaimer from 'src/UIComponents/Disclaimer/Disclaimer'

const DashboardURL = ({ disabled, hideButtonEdit, handleEdit, history }) => {
  const dispatch = useDispatch()
  const { marketplace, custom } = useSelector(state => ({
    marketplace: state.marketplace,
    custom: state.custom
  }))

  const INITIAL_FORM_VALUES = {
    URLPrefix: custom.slug
  }

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [slugShowValidation, setSlugShowValidation] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [disclaimerNextPath, setDisclaimerNextPath] = useState()

  useEffect(() => {
    if (custom.updateCustomSlugSuccess) {
      handleAutoCloseDisclaimerLogin()
      handleEdit(null)
    }
  }, [custom.updateCustomSlugSuccess])

  useEffect(() => {
    if (disclaimerNextPath) {
      history.push(disclaimerNextPath)
    }
  }, [formValues])

  const struct = t.struct({
    URLPrefix: formValidators.Slug
  })

  const handleSubmitForm = () => {
    const isFormValid = t.validate(formValues, struct).isValid()
    setShowDisclaimer(false)
    if (!isFormValid) {
      setSlugShowValidation(true)
    } else {
      setShowDisclaimer(true)
    }
  }

  const handleConfirmSubmitForm = () => {
    setShowDisclaimer(false)
    dispatch(customActions.updateCustomMarketplaceSlug(marketplace.id, {
      name: marketplace.name,
      slug: formValues.URLPrefix
    }))
    handleEdit(null)
  }

  const handleRouteLeave = (location) => {
    setDisclaimerNextPath(location.pathname)
    if (isEqual(INITIAL_FORM_VALUES, formValues) || custom.updateCustomSlugSuccess) {
      setShowDisclaimer(false)
      return true
    }
    setShowDisclaimer(true)
    return false
  }

  const handleOnChange = (values) => {
    setFormValues(values)
  }

  const handleCancel = () => {
    setFormValues(INITIAL_FORM_VALUES)
    setShowDisclaimer(false)
    handleEdit(null)
  }

  const handleAutoCloseDisclaimerLogin = () => {
    location.href = location.href.replace(INITIAL_FORM_VALUES.URLPrefix, formValues.URLPrefix)
  }

  return (
    <Fragment>
      <Prompt message={handleRouteLeave} />
      <UIDashboardURL
        handleEdit={handleEdit}
        handleOnChange={handleOnChange}
        handleSubmit={handleSubmitForm}
        handleCancel={handleCancel}
        formValues={formValues}
        disabled={disabled}
        hideButtonEdit={hideButtonEdit}
        slugShowValidation={slugShowValidation}
        struct={struct}
        isLoading={custom.updateCustomSlugRequesting}
      />
      {
        showDisclaimer &&
        <Disclaimer
          handleSubmit={handleConfirmSubmitForm}
          handleCancel={handleCancel}
        >
          <strong>Deseja mesmo alterar sua URL?</strong><br />
          Todos os seus parceiros perderão acesso ao Dash e Minha Conta até serem<br />
          informados do novo endereço. Você será desconectado automaticamente ao<br />
          salvar esta alteração. Para acessar novamente utilize o seu novo endereço.
        </Disclaimer>
      }
    </Fragment>
  )
}

DashboardURL.propTypes = {
  disabled: PropTypes.bool,
  handleEdit: PropTypes.func,
  hideButtonEdit: PropTypes.bool,
  history: PropTypes.any
}

export default DashboardURL
