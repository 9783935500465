import React, { useState, useEffect, useContext } from 'react'
import t from 'tcomb-form'
import moment from 'moment'
import { Collapse } from 'reactstrap'
import { isEqual } from 'lodash'

import { getFormOptionsWithValidation } from '../../Utils/FormUtils'
import formValidators from '../../Utils/formValidators'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import DatePickerFactory from '../FormFactories/DatePickerFactory'
import Grid from '../../UIComponents/Grid/Grid'
import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import FormZ from '../../UIComponents/FormZ/FormZ'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Button from '../../UIComponents/Button/ButtonDefault'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import { Step } from 'src/UIComponents/Stepper'
import MoneyFactory from '../FormFactories/MoneyFactory'
import CreateSaleStepOneTemplate from '../../UIComponents/TemplatesForm/CreateSaleStepOneTemplate'
import DocumentFactory from '../FormFactories/DocumentFactory'
import CreateSaleStepTwoTemplate from '../../UIComponents/TemplatesForm/CreateSaleStepTwoTemplate'
import { createBuyer, getBuyersByDocument, updateBuyer } from '../../Actions/buyers'
import { createTransaction } from '../../Actions/transactions'
import PostalCodeFactory from '../FormFactories/PostalCodeFactory'
import { getAddressFromPostalCode } from '../../Utils/Utils'
import { useSelector } from 'react-redux'
import SuccessCard from '../../UIComponents/SuccessCard/SuccessCard'
import Label from '../../UIComponents/Label/Label'
import { bigText, darkGray } from '../../Styles/settings/Constants'
import styled from 'styled-components'
import CreateTransactionContext from '../../Context/Transaction/CreateTransactionContext'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'

const CreateSaleComponentForm = ({ handleClose }) => {
  const INITIAL_VALUES = {
    saleData: {
      amount: 0,
      dueDate: '',
      description: ''
    },
    clientData: {
      document: '',
      name: '',
      email: '',
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: ''
    }
  }

  const [showValidation, setShowValidation] = useState({
    saleData: false,
    clientData: false
  })
  const [step, setStep] = useState(1)
  const [buyer, setBuyer] = useState()
  const [values, setValues] = useState(INITIAL_VALUES)
  const [loading, setLoading] = useState(false)

  const { state, setState } = useContext(CreateTransactionContext)

  const { sellerId } = useSelector(({ context }) => {
    return {
      sellerId: context.seller?.id
    }
  })

  const searchAddressFromPostalCode = async () => {
    const { postalCode } = values.clientData
    const {
      logradouro: street,
      complemento: complement,
      bairro: neighborhood,
      localidade: city,
      uf: state
    } = await getAddressFromPostalCode(postalCode)

    setValues({
      ...values,
      clientData: {
        ...values.clientData,
        street,
        complement,
        neighborhood,
        city,
        state
      }
    })
  }

  const options = {
    saleData: {
      template: (locals) => CreateSaleStepOneTemplate(locals),
      fields: {
        amount: {
          label: 'Valor da venda (Mínimo R$ 3,00)',
          factory: MoneyFactory,
          autocomplete: false,
          config: {
            id: 'amount',
            minValue: 3,
            errorMessage: 'O valor deve ser maior que R$ 3,00'
          }
        },
        dueDate: {
          label: 'Data de Vencimento',
          factory: DatePickerFactory,
          autocomplete: false,
          config: {
            id: 'due-date',
            minDate: moment().toDate()
          }
        },
        description: {
          label: 'Descrição (opcional)',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'description'
          }
        }
      }
    },
    clientData: {
      template: (locals) => CreateSaleStepTwoTemplate(locals),
      fields: {
        document: {
          label: 'Cliente (CPF/CNPJ)',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'document'
          }
        },
        name: {
          label: 'Nome',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'name'
          }
        },
        email: {
          label: 'E-mail (opcional)',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'email'
          }
        },
        postalCode: {
          label: 'CEP',
          factory: PostalCodeFactory,
          autocomplete: false,
          config: {
            id: 'postal-code',
            onBlur: searchAddressFromPostalCode
          }
        },
        street: {
          label: 'Endereço',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'address'
          }
        },
        number: {
          label: 'Número',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'number'
          }
        },
        complement: {
          label: 'Complemento',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'complement'
          }
        },
        neighborhood: {
          label: 'Bairro',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'neighborhood'
          }
        },
        city: {
          label: 'Cidade',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'city'
          }
        },
        state: {
          label: 'Estado',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'state'
          }
        }
      }
    }
  }

  const structs = {
    saleData: t.struct({
      amount: formValidators.MinValue(3),
      dueDate: formValidators.Date,
      description: t.maybe(t.String)
    }),
    clientData: t.struct({
      document: formValidators.Document,
      name: formValidators.Name,
      email: t.maybe(t.String),
      postalCode: formValidators.CEP,
      street: formValidators.String,
      number: formValidators.String,
      complement: t.maybe(t.String),
      neighborhood: formValidators.String,
      city: formValidators.String,
      state: formValidators.String
    })
  }

  useEffect(() => {
    setShowValidation({
      saleData: false,
      clientData: false
    })
  }, [step])

  useEffect(() => {
    const document = values.clientData.document
    if (document && (document.length === 11 || document.length === 14)) {
      getBuyer(document)
    }
  }, [values.clientData.document])

  const onChange = (newValues, stepName) => {
    setValues({
      ...values,
      [stepName]: {
        ...newValues
      }
    })
  }

  const reset = () => {
    setStep(1)
    setValues(INITIAL_VALUES)
    setBuyer(null)
    setState({
      transaction: null,
      success: null,
      error: null
    })
    setShowValidation({
      saleData: false,
      clientData: false
    })
  }

  const getBuyer = async (document) => {
    const buyer = await getBuyersByDocument({ taxpayer_id: document })
    if (buyer) {
      setBuyer(buyer)
      setValues({
        ...values,
        clientData: {
          ...values.clientData,
          name: `${buyer.first_name} ${buyer.last_name}`,
          email: buyer.email,
          street: buyer.address?.line1,
          number: buyer.address?.line2,
          complement: buyer.address?.line3,
          neighborhood: buyer.address?.neighborhood,
          city: buyer.address?.city,
          state: buyer.address?.state,
          postalCode: buyer.address?.postal_code
        }
      })
    } else {
      setBuyer(null)
      setValues({
        ...values,
        clientData: {
          ...values.clientData,
          name: '',
          email: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          postalCode: ''
        }
      })
    }
  }

  const createOrUpdateBuyer = async () => {
    const { clientData } = values
    const nameSplitted = clientData.name.split(' ')
    let newBuyer = null
    let newBuyerData = {
      first_name: nameSplitted.shift(1),
      last_name: nameSplitted.toString().replace(',', ' '),
      email: clientData.email,
      taxpayer_id: clientData.document,
      address: {
        line1: clientData.street,
        line2: clientData.number,
        line3: clientData.complement,
        neighborhood: clientData.neighborhood,
        postal_code: clientData.postalCode,
        city: clientData.city,
        state: clientData.state,
        country_code: 'BR'
      }
    }

    if (!buyer) {
      newBuyer = await createBuyer(newBuyerData)
      if (!newBuyer) {
        setLoading(false)
        setState({
          ...state,
          success: false,
          error: true
        })
        return
      }
    } else {
      const buyerData = {
        first_name: buyer.first_name,
        last_name: buyer.last_name,
        email: buyer.email,
        [buyer.taxpayer_id ? 'taxpayer_id' : 'ein']: buyer.taxpayer_id || buyer.ein,
        address: buyer.address
      }

      if (!isEqual(buyerData, newBuyerData)) {
        newBuyer = await updateBuyer({
          ...newBuyerData,
          id: buyer.id
        })
      }
    }

    return newBuyer
  }

  const onSubmit = async () => {
    let isFormValid = null
    if (step === 1) {
      isFormValid = t.validate(values.saleData, structs.saleData).isValid()
    } else if (step === 2) {
      isFormValid = t.validate(values.clientData, structs.clientData).isValid()
    }

    if (!isFormValid && step !== 3) {
      setShowValidation({
        saleData: step === 1,
        clientData: step === 2
      })
      setLoading(false)
      return
    }

    if (step < 3) {
      setStep(step + 1)
    } else {
      setState({
        error: false,
        success: false
      })
      setLoading(true)
      const newBuyer = await createOrUpdateBuyer()

      if (!newBuyer && !buyer) {
        setState({
          ...state,
          success: false,
          error: true
        })
        setLoading(false)
      }

      const createdTransaction = await createTransaction({
        amount: Math.round(values.saleData.amount * 100),
        currency: 'BRL',
        description: values.saleData.description,
        on_behalf_of: sellerId,
        customer: buyer?.id || newBuyer?.id,
        payment_type: 'boleto',
        payment_method: {
          expiration_date: moment(values.saleData.dueDate).format('YYYY-MM-DD')
        }
      })

      if (createdTransaction) {
        setState({
          ...state,
          success: true,
          transaction: createdTransaction
        })
      } else {
        setState({
          ...state,
          success: false,
          error: true
        })
      }
    }
    setLoading(false)
  }

  const handleClickSeeBoleto = () => {
    const { transaction: { payment_method: paymentMethod } } = state
    window.open(paymentMethod.url, '_blank')
  }

  const isAfterFirstStep = step > 1
  const isAfterSecondStep = step > 2

  if (state.success) {
    return (
      <>
        <HeaderSidebar
          title={<><strong>Nova</strong> Venda</>}
          handleClose={handleClose}
        />
        <SidebarContainer>
          <SuccessCard>
            <br />
            <Label color={darkGray} fontSize={bigText}>Boleto gerado com sucesso!</Label>
            <br />
            <br />
            <SuccessScreenButtonsContainer>
              <Button ghost outline onClick={reset}>Nova venda</Button>
              <Button onClick={handleClickSeeBoleto}>Ver boleto</Button>
            </SuccessScreenButtonsContainer>
          </SuccessCard>
        </SidebarContainer>
      </>
    )
  }

  return (
    <>
      <HeaderSidebar
        goBack={step > 1 ? () => setStep(step - 1) : null}
        title={<><strong>Nova</strong> Venda</>}
        handleClose={handleClose}
      />
      <SidebarContainer>
        <Grid.Col cols={16}>
          <Grid.Row>
            <Grid.Col cols={1}>
              <Step success final={isAfterFirstStep} successColor />
            </Grid.Col>
            <Grid.Col cols={14}>
              <Grid.Row auto>
                <SubTitle
                  onClick={isAfterFirstStep ? () => setStep(1) : undefined}
                  small
                  line>
                  Dados <strong>da Venda</strong>
                </SubTitle>
              </Grid.Row>
              <Collapse isOpen={step === 1}>
                <Grid.Row auto bigMarginBottom>
                  <FormZ
                    options={showValidation.saleData
                      ? getFormOptionsWithValidation(options.saleData, values.saleData, structs.saleData)
                      : options.saleData}
                    struct={structs.saleData}
                    values={values.saleData}
                    onChange={(values) => onChange(values, 'saleData')}
                    disabledForm={isAfterFirstStep} />
                </Grid.Row>
                <Grid.Row bigMarginBottom>
                  <Grid.Col cols={5}>
                    <Button onClick={onSubmit} data-test='add-seller-btn-data' >
                      Continuar
                    </Button>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row />
              </Collapse>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={1}>
              <Step success={isAfterFirstStep} final={isAfterSecondStep} successColor={isAfterFirstStep} />
            </Grid.Col>
            <Grid.Col cols={14}>
              <Grid.Row auto>
                <SubTitle
                  onClick={isAfterSecondStep ? () => setStep(2) : undefined}
                  small
                  line>
                  Dados <strong>do Cliente</strong>
                </SubTitle>
              </Grid.Row>
              <Collapse isOpen={step === 2}>
                <Grid.Row auto bigMarginBottom>
                  <FormZ
                    options={showValidation.clientData
                      ? getFormOptionsWithValidation(options.clientData, values.clientData, structs.clientData)
                      : options.clientData}
                    struct={structs.clientData}
                    values={values.clientData}
                    onChange={(values) => onChange(values, 'clientData')}
                    disabledForm={isAfterFirstStep} />
                </Grid.Row>
                <Grid.Row bigMarginBottom>
                  <Grid.Col cols={5}>
                    <Button onClick={onSubmit} data-test='add-seller-btn-data' >
                      Continuar
                    </Button>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row />
              </Collapse>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={1}>
              <Step success={isAfterSecondStep} isLastStep />
            </Grid.Col>
            <Grid.Col cols={14}>
              <Grid.Row auto>
                <SubTitle
                  onClick={isAfterSecondStep ? () => setStep(3) : undefined}
                  small
                  line>
                  Confirmação
                </SubTitle>
              </Grid.Row>
              <Collapse isOpen={step === 3}>
                <Grid.Row auto bigMarginBottom>
                  <Button ghost outline onClick={() => setStep(3)} data-test='add-seller-btn-data' >
                    Cancelar
                  </Button>
                  <Button onClick={onSubmit} data-test='add-seller-btn-data' isLoading={loading}>
                    Gerar boleto
                  </Button>
                </Grid.Row>
                {
                  state.error && <AlertMessage
                    type='error'
                    error={{
                      message: 'Não foi possível gerar o boleto.'
                    }}
                  />
                }
              </Collapse>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </SidebarContainer>
    </>
  )
}

export default CreateSaleComponentForm

const SuccessScreenButtonsContainer = styled.div`
  display: flex;
  
  >button {
    margin-right: 10px;
  }
`
