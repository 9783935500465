import React from 'react'
import ContentLoader from 'react-content-loader'

export const ProgressionChartLoader = () => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={252}
    viewBox='0 0 345 252'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
  >
    <rect x='0' y='0' rx='5' ry='5' width='345' height='242' />
  </ContentLoader>
)

export default ProgressionChartLoader
