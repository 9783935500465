import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import { useHistory, useParams } from 'react-router-dom'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import { ProgressionChart } from './ProgressionChart'
import Commissions from 'src/Components/CommissionSidebar/Commissions'

const CommissionSidebar = ({ state }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const params = useParams()
  const history = useHistory()

  const handleCloseSidebar = () => {
    setSidebarOpen(false)
    setTimeout(() => {
      history.push(`/${params.routePath}`)
    }, 500)
  }

  return (
    <Sidebar className={state} show={sidebarOpen}>
      <HeaderSidebar
        title={
          <Fragment>
            <strong>Histórico</strong> de markups
          </Fragment>
        }
        handleClose={handleCloseSidebar}
      />
      <SidebarContainer id='CommissionsSidebar'>
        <ProgressionChart />
        <br />
        <br />
        <br />
        <Commissions onClose={handleCloseSidebar} />
      </SidebarContainer>
    </Sidebar>
  )
}

CommissionSidebar.propTypes = {
  state: PropTypes.string
}

export default CommissionSidebar
