import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getCommissions } from 'src/Actions/commissions'
import { createExport, redoFile, deleteFile } from 'src/Actions/exports'

import Dots from 'src/UIComponents/Dots/Dots'
import CommissionsList from 'src/UIComponents/CommissionsList/CommissionsList'
import MessageUpLine from 'src/UIComponents/MessageUpLine/MessageUpLine'
import CommissionsListLoader from 'src/Components/CommissionSidebar/CommissionsListLoader'

const Commissions = ({ onClose }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCommissions())
  }, [])

  const {
    commissions,
    commissionsRequesting,
    hasMoreCommissions,
    commissionsPage
  } = useSelector(({ commissions }) => commissions)

  const [commissionsList, setCommissionsList] = useState(commissions || [])

  const {
    id: sellerId
  } = useSelector(({ marketplace: { details } }) => details && details.customer)

  const {
    watchFiles
  } = useSelector(({ exports }) => exports)

  useEffect(() => {
    const updatedCommissions = commissions.map(commission => {
      const { filename } = commission
      const watchedCommission = watchFiles.find(file => file.filename === filename)
      if (watchedCommission) {
        return {
          ...commission,
          status: watchedCommission.status
        }
      }
      return commission
    })

    setCommissionsList(updatedCommissions)
  }, [watchFiles, commissions])

  const fetchMoreCommissions = () => {
    dispatch(getCommissions({ page: commissionsPage + 1 }))
  }

  const handleDeleteExport = (commission) => {
    dispatch(deleteFile(commission.filename, 'commissions', true))
    dispatch(getCommissions())
  }

  const handleClickCommission = ({ id, status, filename, url, commissionDate }, format) => {
    if (!status) {
        const shouldUseCommissionV2 = new Date(commissionDate) >= new Date('2024-11-01')
        dispatch(createExport(
            shouldUseCommissionV2 ? 'commissions-v2' : 'commissions',
            format,
            { commission_id: id, seller_id: sellerId },
            []))
        onClose()
    }
    if (status === -1) {
      dispatch(redoFile(filename, 'commissions', format))
      onClose()
    }
    if (status === 2) {
      window.location.href = url
    }
  }

  if (commissionsRequesting) {
    return <CommissionsListLoader />
  }

  return (
    <InfiniteScroll
      scrollableTarget='CommissionsSidebar'
      next={fetchMoreCommissions}
      hasMore={hasMoreCommissions}
      loader={<Dots outline />}
      dataLength={commissionsList.length}
      endMessage={<MessageUpLine>
        Isso é tudo
      </MessageUpLine>}
    >
      <CommissionsList
        groupedCommissions={commissionsList}
        handleActionClick={handleClickCommission}
        handleDeleteExport={handleDeleteExport}
      />
    </InfiniteScroll>
  )
}

Commissions.defaultProps = {
  onClose: () => { /* noop */ }
}

Commissions.propTypes = {
  onClose: PropTypes.func
}

export default Commissions
