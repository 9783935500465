import React from 'react'
import ContentLoader from 'react-content-loader'

const CommissionsListLoader = (props) => (
  <ContentLoader
    speed={4}
    width={400}
    height={540}
    viewBox='0 0 400 540'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='60' height='250' />
    <rect x='70' y='0' rx='5' ry='5' width='330' height='120' />
    <rect x='70' y='130' rx='5' ry='5' width='330' height='120' />
    <rect x='0' y='265' rx='5' ry='5' width='60' height='120' />
    <rect x='70' y='265' rx='5' ry='5' width='330' height='120' />
    <rect x='0' y='400' rx='5' ry='5' width='60' height='120' />
    <rect x='70' y='400' rx='5' ry='5' width='330' height='120' />
  </ContentLoader>
)

export default CommissionsListLoader
