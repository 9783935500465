import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import t from 'tcomb-form'
import Button from 'src/UIComponents/Button/ButtonDefault'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import ChangePassTemplate from 'src/UIComponents/TemplatesForm/ChangePassTemplate'
import PasswordValidation from 'src/UIComponents/PasswordValidation/PasswordValidation'
import {
  getPasswordValidation,
  getFormOptionsWithValidation
} from 'src/Utils/FormUtils'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetUserUpdatePass,
  updateLoggedInUserPassword
} from 'src/Actions/user'
import Card from 'src/UIComponents/Card/Card'
import SubTitle from 'src/UIComponents/Title/SubTitle'
import formValidators from '../../Utils/formValidators'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const ChangePassCard = ({ handleCloseCard }) => {
  const [showValidation, setShowValidation] = useState(false)
  const [showMFA, setShowMFA] = useState(false)

  const [formValues, setFormValues] = useState({
    currentPass: '',
    newPass: '',
    confirmNewPass: ''
  })

  const { userId, updatePassSuccess, updatePassRequesting } = useSelector(
    ({ user }) => ({
      userId: user.id,
      updatePassSuccess: user.updatePassSuccess,
      updatePassRequesting: user.updatePassRequesting
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (updatePassSuccess) {
      resetFormValues()
      handleCloseCard()
    }
  }, [updatePassSuccess])

  useEffect(() => {
    return () => dispatch(resetUserUpdatePass())
  }, [])

  const resetFormValues = () =>
    setFormValues({
      currentPass: '',
      newPass: '',
      confirmNewPass: ''
    })

  const passwordValidation = getPasswordValidation(
    formValues.newPass,
    formValues.confirmNewPass
  )

  const formOptions = {
    template: (locals) => ChangePassTemplate(locals),
    fields: {
      currentPass: {
        label: 'Senha atual',
        factory: InputDefaultFactory,
        config: {
          type: 'password',
          id: 'changePass-currentPass'
        }
      },
      newPass: {
        label: 'Nova senha',
        factory: InputDefaultFactory,
        config: {
          type: 'password',
          id: 'changePass-newPass'
        }
      },
      confirmNewPass: {
        label: 'Confirmar nova senha',
        factory: InputDefaultFactory,
        config: {
          type: 'password',
          id: 'changePass-confirmNewPass'
        }
      }
    }
  }

  const formStruct = t.struct({
    currentPass: formValidators.String,
    newPass: formValidators.String,
    confirmNewPass: formValidators.String
  })

  const changePassword = () => {
    const { currentPass, newPass } = formValues

    const isValid = t.validate(formValues, formStruct).isValid()
    if (!isValid) setShowValidation(true)

    if (!passwordValidation.isValid || !currentPass) return

    dispatch(
      updateLoggedInUserPassword(userId, {
        current_password: currentPass,
        new_password: newPass
      })
    )
  }

  const handleOpenMFA = () => {
    const { currentPass } = formValues

    const isValid = t.validate(formValues, formStruct).isValid()
    if (!isValid) setShowValidation(true)

    if (!passwordValidation.isValid || !currentPass) return
    setShowMFA(true)
  }

  const close = () => {
    resetFormValues()
    handleCloseCard()
  }

  return (
    <Fragment>
      {showMFA && (
        <MFASingleComponent
          isSidebar
          showComponent={setShowMFA}
          onSubmit={changePassword}
        />
      )}
      <Card buttonClose handleClose={close} noTheme>
        <Grid noPadding>
          <Grid.Row bigMarginBottom>
            <Grid.Col cols={4}>
              <SubTitle small>
                Alterar <strong>Senha</strong>
              </SubTitle>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row auto>
            <FormZ
              struct={formStruct}
              values={formValues}
              options={
                showValidation
                  ? getFormOptionsWithValidation(
                      formOptions,
                      formValues,
                      formStruct
                    )
                  : formOptions
              }
              onChange={setFormValues}
            />
          </Grid.Row>
          <Grid.Row auto>
            <PasswordValidation {...passwordValidation} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={7}>
              <Button
                isLoading={updatePassRequesting}
                onClick={handleOpenMFA}
                data-test='my-profile-confirm-change-password-btn'
              >
                Alterar Senha
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
    </Fragment>
  )
}

ChangePassCard.propTypes = {
  handleCloseCard: PropTypes.func
}

export default ChangePassCard
