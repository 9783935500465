import React from 'react'
import PropTypes from 'prop-types'

import Grid from 'src/UIComponents/Grid/Grid'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import Button from 'src/UIComponents/Button/ButtonDefault'

import { darkGray } from 'src/Styles/settings/Constants'

const ErrorTransferComponent = ({ handleClose, handleGoBack }) => {
  const title = 'Parece que algo deu errado'
  const description = 'Estamos trabalhando para corrigir o problema.'
  const iconPath = '/assets/icons/alerts/alert.svg'

  return (
    <>
      <HeaderSidebar
        title={<><strong>Fazer </strong> Transferência</>}
        handleClose={handleClose}
        goBack={handleGoBack}
      />
      <SidebarContainer emptyState>
        <br />
        <br />
        <br />
        <br />
        <Grid.Row>
          <Grid.Col cols={16}>
            <EmptyState title={title} description={description} iconColor={darkGray} iconSrc={iconPath} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col offset={4} cols={8}>
            <Button type='submit' isLoading={false} onClick={handleGoBack} >
              Tentar Novamente
            </Button>
          </Grid.Col>
        </Grid.Row>
      </SidebarContainer>
    </>
  )
}

ErrorTransferComponent.propTypes = {
  handleClick: PropTypes.func,
  handleClose: PropTypes.func
}

export default ErrorTransferComponent
