import React from 'react'
import { DocumentCard } from 'src/UIComponents/DocumentCard'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { uploadSellerDocument, resetUploadSellerDocument, downloadSellerDocument } from 'src/Actions/seller'

const DocumentCardComponent = ({ header, description, files, category, ...props }) => {
  const {
    id: sellerId,
    uploadSellerDocumentRequest,
    uploadSellerDocumentError,
    getSellerDocumentsRequest
  } = useSelector(({ context, seller }) => {
    return {
      id: context.seller?.id,
      uploadSellerDocumentRequest: seller.uploadSellerDocumentRequest,
      uploadSellerDocumentError: seller.uploadSellerDocumentError,
      getSellerDocumentsRequest: seller.getSellerDocumentsRequest
    }
  })

  const dispatch = useDispatch()

  const handleFileSubmit = file => {
    return dispatch(uploadSellerDocument(sellerId, file, category))
  }

  const handleDownloadFile = (documentId, documentName) => {
    return dispatch(downloadSellerDocument(documentId, documentName))
  }

  const handleCancelSubmit = () => {
    return dispatch(resetUploadSellerDocument())
  }

  return (
    <DocumentCard
      {...props}
      header={header}
      description={description}
      files={files}
      loadingContent={getSellerDocumentsRequest}
      loadingRequest={uploadSellerDocumentRequest}
      onFileSubmit={handleFileSubmit}
      onDownloadFile={handleDownloadFile}
      error={uploadSellerDocumentError}
      onCancelSubmit={handleCancelSubmit} />
  )
}

DocumentCardComponent.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string
  })),
  category: PropTypes.oneOf(['identificacao', 'atividade', 'residencia']).isRequired
}

export { DocumentCardComponent as DocumentCard }
