import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'

import Button from 'src/UIComponents/Button/ButtonDefault'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import { CreateTransactionContextProvider } from '../../Context/Transaction/CreateTransactionContext'
import CreateSaleComponentForm from './CreateSaleComponentForm'

const CreateSaleComponent = ({ buttonInversed }) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch({
    path: '*/nova-venda'
  })

  const [showSidebar, setShowSidebar] = useState(!!match)

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        setShowSidebar(true)
      }, 500)
    }
  }, [])

  const handleClose = () => {
    setShowSidebar(false)

    setTimeout(() => {
      history.push(match.params[0])
    }, 500)
  }

  const handleClick = () => {
    history.push(`${location.pathname}/nova-venda`)

    setTimeout(() => {
      setShowSidebar(true)
    }, 500)
  }

  return (
    <>
      <Button ghost={buttonInversed} outline={buttonInversed} widthAuto onClick={handleClick}>Nova Venda</Button>
      {
        match && <Sidebar show={showSidebar}>
          <CreateTransactionContextProvider>
            <CreateSaleComponentForm handleClose={handleClose} />
          </CreateTransactionContextProvider>
        </Sidebar>
      }
    </>
  )
}

export default CreateSaleComponent
