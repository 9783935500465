import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BarChart from 'src/UIComponents/Charts/BarChart'
import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import { darkerGray, darkGray, normalText } from 'src/Styles/settings/Constants'
import { getCommissionProgression } from 'src/Actions/kpi'
import ProgressionChartLoader from 'src/Components/CommissionSidebar/ProgressionChartLoader'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import ProgressiveChartIcon from 'src/Assets/icons/others/progressive-chart.svg'
import { ContainerEmptyState } from 'src/Containers/Home/styles'

const ProgressionChart = () => {
  const dispatch = useDispatch()

  const {
    commissionProgression,
    color,
    lastCommission
  } = useSelector(({ kpi, custom, commissions }) => ({
    commissionProgression: kpi.commissions.succeeded,
    color: custom.color,
    lastCommission: commissions.lastCommission
  }))

  useEffect(() => {
    if (lastCommission) {
      dispatch(getCommissionProgression())
    }
  }, [lastCommission])

  const barChartDisplay = () => {
    const commissionsFiltered = commissionProgression.historic && commissionProgression.historic.filter(item => item.name !== null)
    if (lastCommission && commissionsFiltered.length === 0) {
      return (
        <ContainerEmptyState>
          <EmptyState
            iconSrc={ProgressiveChartIcon}
            description='Sem progressão de Markup nos últimos 12 meses.'
            iconColor={darkerGray}
            iconSize='small'
          />
        </ContainerEmptyState>
      )
    }
    if (!lastCommission && commissionsFiltered.length === 0) {
      return (
        <ContainerEmptyState>
          <EmptyState
            iconSrc={ProgressiveChartIcon}
            description='Ainda sem progressão de Markup.'
            iconColor={darkerGray}
            iconSize='small'
          />
        </ContainerEmptyState>
      )
    }

    return (
      <BarChart
        color={color}
        width='100%'
        layout='horizontal'
        height={252}
        data={commissionProgression.historic}
        itemsType='commission'
      />
    )
  }

  return (
    <Card>
      <Label
        bold
        uppercase
        color={darkGray}
        fontSize={normalText}
      >
        Progressão de Markups
      </Label>
      {
        commissionProgression.request
          ? <ProgressionChartLoader />
          : barChartDisplay()
      }
    </Card>
  )
}

export { ProgressionChart }
