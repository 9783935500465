import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { useDispatch, useSelector } from 'react-redux'

import { darkGray } from 'src/Styles/settings/Constants'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import formValidators from '../../Utils/formValidators'
import FormZ from '../../UIComponents/FormZ/FormZ'
import Grid from '../../UIComponents/Grid/Grid'
import { listBankAccounts } from '../../Actions/bankAccounts'
import CreateTransferContext from '../../Context/Transfer/CreateTransferContext'
import SelectBankAccountLoader from '../SelectBankAccount/SelecBankAccountLoader'

const bankAccountStruct = t.struct({
  bankAccount: formValidators.String
})

const BankAccountForm = ({ options, handleChange, values }) => {
  const dispatch = useDispatch()
  const { setState: setCreateTransferState } = useContext(CreateTransferContext)
  const [formOptions, setFormOptions] = useState(options)
  const title = 'Não existe conta bancária cadastrada'
  const description = 'Para adicionar é necessário entrar em contato com o estabelecimento comercial para cadastrar em seu portal.'
  const iconPath = '/assets/icons/alerts/alert.svg'

  const {
    bankAccounts,
    bankAccountsRequesting,
    sender
  } = useSelector(({ recipientMeans, context }) => {
    return {
      bankAccountsRequesting: recipientMeans.isBankAccountsRequesting,
      bankAccounts: recipientMeans.bankAccounts,
      sender: context.seller
    }
  })

  useEffect(() => {
    if (bankAccounts && bankAccounts.length && !bankAccountsRequesting) {
      const options = []
      options.push(...bankAccounts.filter(bankAccount => bankAccount.bank_code !== '10001'))
      setFormOptions({
        ...formOptions,
        fields: {
          bankAccount: {
            ...formOptions.fields.bankAccount,
            config: {
              options: options
            }
          }
        }
      })

      handleChange({ bankAccount: bankAccounts[0] }, 'bankAccountValues')
      setCreateTransferState({ receiver: bankAccounts[0] })
    }
  }, [bankAccounts, bankAccountsRequesting])

  useEffect(() => {
    if (sender) {
      dispatch(listBankAccounts(sender.id))
    }
  }, [sender])

  if (bankAccountsRequesting) {
    return (
      <Grid.Row auto bigMarginBottom>
        <SelectBankAccountLoader />
      </Grid.Row>
    )
  }

  return (
    <>
      { bankAccounts.length <= 0
        ? (
          <Grid.Row>
            <Grid.Col cols={16}>
              <EmptyState
                title={title}
                description={description}
                iconColor={darkGray}
                iconSrc={iconPath}
              />
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Grid.Row auto bigMarginBottom>
            <FormZ
              options={formOptions}
              struct={bankAccountStruct}
              values={values}
              onChange={handleChange}
              isRequesting={bankAccountsRequesting}
            />
          </Grid.Row>)
      }

    </>
  )
}

BankAccountForm.struct = bankAccountStruct

BankAccountForm.propTypes = {
  options: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func
}

export default BankAccountForm
