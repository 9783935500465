import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as customActions from 'src/Actions/custom'
import Grid from 'src/UIComponents/Grid/Grid'
import Title from 'src/UIComponents/Title/Title'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import TitleInfoPage from 'src/UIComponents/Title/TitleInfoPage'
import VisualIdentity from 'src/Components/CustomizeComponent/VisualIdentity'
import DashboardURL from 'src/Components/CustomizeComponent/DashboardURL'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import VisualIdentitySidebar from 'src/Components/VisualIdentitySidebar/VisualIdentitySidebar'
import SupportInfo from 'src/Components/CustomizeComponent/SupportInfo'
import { useGetPermissionProfile } from '../../Hooks/useGetPermissionProfile'

const Customize = ({ history }) => {
  const [currentEditingForm, setCurrentEditingForm] = useState()

  const { getProfile } = useGetPermissionProfile()
  const profile = getProfile()

  const hideEdit = profile === 'full_visualization'

  const handleEdit = (form) => {
    setCurrentEditingForm(form)
  }

  const resetInEditMode = () => setCurrentEditingForm('')

  return (
    <Grid noPadding>
      <TitleInfoPage>Configurações do marketplace {'>'}</TitleInfoPage>
      <Helmet>Personalização</Helmet>
      <Grid.Row largeMarginBottom>
        <Grid.Col offset={1} cols={15}>
          <Title big noPadding>
            <strong>Personalização</strong>
          </Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row largeMarginBottom auto>
        <VisualIdentity
          hideButtonEdit={hideEdit || !!currentEditingForm}
          handleEdit={handleEdit}
        />
      </Grid.Row>
      <Grid.Row largeMarginBottom auto>
        <DashboardURL
          history={history}
          disabled={currentEditingForm !== 'dashboardURL'}
          hideButtonEdit={hideEdit || !!currentEditingForm}
          handleEdit={handleEdit}
          handleSubmit={customActions.updateCustomMarketplaceSlug}
        />
      </Grid.Row>
      <Grid.Row largeMarginBottom auto>
        <SupportInfo
          history={history}
          disabled={currentEditingForm !== 'supportInfo'}
          hideButtonEdit={hideEdit || !!currentEditingForm}
          handleEdit={handleEdit}
        />
      </Grid.Row>
      <Sidebar show={currentEditingForm === 'visualIdentity'}>
        <VisualIdentitySidebar handleCloseSidebar={resetInEditMode} />
      </Sidebar>
    </Grid>
  )
}

Customize.propTypes = {
  history: PropTypes.object
}

export default Customize
