import React, { Fragment, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { useDispatch, useSelector } from 'react-redux'

import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import MoneyFactory from '../FormFactories/MoneyFactory'
import DocumentFactory from '../FormFactories/DocumentFactory'
import SelectBankAccountFactory from '../FormFactories/SelectBankAccountFactory'
import formValidators from '../../Utils/formValidators'
import FormZ from '../../UIComponents/FormZ/FormZ'
import RadioGroupFactory from '../FormFactories/RadioGroupFactory'
import Button from '../../UIComponents/Button/ButtonDefault'
import CreateTransferTemplate from '../../UIComponents/TemplatesForm/CreateTransferTemplate'
import Grid from '../../UIComponents/Grid/Grid'
import { getFormOptionsWithValidation } from '../../Utils/FormUtils'
import P2PForm from './P2PForm'
import LastStepForm from './LastStepForm'
import { createBankAccountTransfer, createP2PTransfer, resetCreateTransfer } from '../../Actions/transfers'
import CreateTransferContext from '../../Context/Transfer/CreateTransferContext'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import ErrorTransferComponent from './ErrorTransferComponent'
import SuccessTransferComponent from './SuccessTransferComponent'
import BankAccountForm from './BankAccountForm'
import Label from 'src/UIComponents/Label/Label'
import { darkGray } from '../../Styles/settings/Constants'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'
import BalanceSeller from '../BalanceSeller/BalanceSeller'

const CreateTransferComponentForm = ({ handleClose }) => {
  const dispatch = useDispatch()
  const { state: { receiver }, setState: setCreateTransferState } = useContext(CreateTransferContext)

  const {
    currentBalance,
    createTransferError,
    createTransferRequesting,
    createTransferSuccess,
    sender,
    bankAccounts
  } = useSelector(({ context, user, transfers, recipientMeans, balances }) => {
    return {
      sender: context.seller,
      currentBalance: balances.currentBalance / 100 || '0.00',
      username: user.username,
      createTransferRequesting: transfers.createTransferRequesting,
      createTransferError: transfers.createTransferError,
      createTransferSuccess: transfers.createTransferSuccess,
      bankAccounts: recipientMeans.bankAccounts
    }
  })

  const [loading, setLoading] = useState(false)
  const [showFormValidation, setShowFormValidation] = useState(false)
  const [passwordError, setPasswordError] = useState()
  const [showMFA, setShowMFA] = useState(false)
  const [formValues, setFormValues] = useState({
    values: {
      amount: null,
      transferType: null
    },
    p2pValues: {
      document: null
    },
    lastStepValues: {
      description: null
    },
    bankAccountValues: {
      bankAccount: null
    }
  })
  const [formOptions, setFormOptions] = useState({
    options: {
      template: (locals) => CreateTransferTemplate(locals, 1),
      fields: {
        amount: {
          label: <>Valor da <strong>Transferência</strong></>,
          factory: MoneyFactory,
          autocomplete: false,
          config: {
            id: 'amount',
            maxValue: currentBalance,
            errorMessage: 'Saldo insuficiente na conta'
          }
        },
        transferType: {
          label: <>Transferir <strong>para</strong></>,
          factory: RadioGroupFactory,
          autocomplete: false,
          config: {
            options: [{ value: 'p2p', label: 'Conta Digital' }, { value: 'bankAccount', label: 'Conta Bancária' }]
          }
        }
      }
    },
    p2pOptions: {
      template: (locals) => CreateTransferTemplate(locals, 2),
      fields: {
        document: {
          label: 'CPF ou CNPJ',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'document'
          }
        },
        description: {
          label: 'Descrição',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'description'
          }
        }
      }
    },
    bankAccountOptions: {
      fields: {
        bankAccount: {
          label:
            bankAccounts.length > 0
              ? 'Escolha uma conta cadastrada'
              : null,
          factory: SelectBankAccountFactory,
          autocomplete: false,
          config: {
            options: []
          }
        }
      }
    },
    lastStepOptions: {
      template: (locals) => CreateTransferTemplate(locals, 3),
      fields: {
        description: {
          label: 'Descrição',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'description'
          }
        }
      }
    }
  })

  const struct = t.struct({
    amount: formValidators.MaxValue(currentBalance),
    transferType: formValidators.String
  })

  useEffect(() => {
    setCreateTransferState({
      receiver: null
    })
  }, [])

  useEffect(() => {
    if (receiver) {
      setShowFormValidation(false)
      resetFieldErrors('p2pOptions', 'document')
    }
  }, [receiver])

  useEffect(() => {
    setCreateTransferState({
      receiver: null
    })
  }, [formValues.values.transferType])

  useEffect(() => {
    if (formValues.values.transferType === 'p2p') {
      setCreateTransferState({ receiver: null })
    }
  }, [formValues.values.transferType])

  const resetFieldErrors = (form, field) => {
    setShowFormValidation(false)
    setFormOptions({
      ...formOptions,
      [form]: {
        ...formOptions[form],
        fields: {
          ...formOptions[form].fields,
          [field]: {
            ...formOptions[form].fields[field],
            hasError: false,
            error: null
          }
        }
      }
    })
  }

  const handleOnChange = (values, step) => {
    setFormValues({
      ...formValues,
      [step]: {
        ...formValues[step],
        ...values
      }
    })
  }

  const handleGoBack = () => {
    if (createTransferError) {
      dispatch(resetCreateTransfer(true))
    } else {
      setShowFormValidation(false)
      setPasswordError(null)
      setCreateTransferState({
        receiver: null
      })
      handleOnChange({
        ...formValues.amount,
        transferType: null
      }, 'values')
    }
  }

  const submitForm = async () => {
    if (receiver) {
      if (formValues.values.transferType === 'p2p') {
        dispatch(createP2PTransfer(
          formValues.values.amount * 100,
          formValues.lastStepValues.description,
          receiver.id
        ))
      } else {
        dispatch(createBankAccountTransfer(
          formValues.values.amount * 100,
          formValues.lastStepValues.description,
          formValues.bankAccountValues.bankAccount.id
        ))
      }
    }
  }

  const handleSubmit = async () => {
    setPasswordError(null)
    setLoading(true)
    let isFormValid = t.validate(formValues.values, struct).isValid()

    if (formValues.values.transferType === 'p2p') {
      isFormValid = isFormValid &&
        t.validate(formValues.p2pValues, P2PForm.struct).isValid() &&
        !formOptions.p2pOptions.fields.document.hasError
    }

    if (receiver) {
      isFormValid = isFormValid &&
        t.validate(formValues.lastStepValues, LastStepForm.struct).isValid()
    }

    if (!isFormValid) {
      setShowFormValidation(true)
    } else {
      setShowFormValidation(false)
      setShowMFA(true)
    }
    setLoading(false)
  }

  if (createTransferSuccess) {
    return (
      <SuccessTransferComponent transfer={createTransferSuccess} sender={sender} handleClose={handleClose} />
    )
  }

  if (createTransferError) {
    return (
      <ErrorTransferComponent handleClose={handleClose} handleGoBack={handleGoBack} />
    )
  }

  return (
    <Fragment>
      {showMFA && (
        <MFASingleComponent showComponent={setShowMFA} onSubmit={submitForm} isSidebar />
      )}
      <HeaderSidebar
        title={<><strong>Fazer</strong> Transferência</>}
        handleClose={handleClose}
        goBack={receiver && handleGoBack}
      />
      <SidebarContainer>
        <Grid.Row auto bigMarginBottom>
          <BalanceSeller shortView />
        </Grid.Row>
        <Grid.Row auto>
          <FormZ
            options={showFormValidation
              ? getFormOptionsWithValidation(formOptions.options, formValues.values, struct)
              : formOptions.options}
            struct={struct}
            values={formValues.values}
            onChange={(values) => handleOnChange(values, 'values')}
            isRequesting={false}
          />
        </Grid.Row>
        {formValues.values.transferType === 'p2p' &&
          <P2PForm
            options={formOptions.p2pOptions}
            values={formValues.p2pValues}
            handleChange={handleOnChange}
            showFormValidation={showFormValidation}
            setLoading={setLoading}
          />
        }

        {formValues.values.transferType === 'bankAccount' &&
          <BankAccountForm
            handleChange={(values) => handleOnChange(values, 'bankAccountValues')}
            options={formOptions.bankAccountOptions}
            values={formValues.bankAccountValues}
          />
        }

        {receiver &&
          <>
            <LastStepForm
              options={formOptions.lastStepOptions}
              values={formValues.lastStepValues}
              handleChange={handleOnChange}
              showFormValidation={showFormValidation}
              error={passwordError} />
          </>
        }
        {
          formValues.values.transferType === 'bankAccount' && formValues.bankAccountValues.bankAccount !== null &&
          <>
            <br />
            <Grid.Row auto bigMarginBottom>
              <Label textAlign='center' color={darkGray}>
                Transferências solicitadas em dia útil antes das 11h, serão realizadas no mesmo dia. Após esse horário, no próximo dia útil.
              </Label>
            </Grid.Row>
          </>
        }

        {
          (formValues.values.transferType === 'p2p' || formValues.bankAccountValues.bankAccount !== null) && (
            <Grid.Row>
              <Grid.Col offset={5} cols={6}>
                <Button
                  withAuto
                  onClick={handleSubmit}
                  isLoading={loading || createTransferRequesting}
                >
                  {receiver ? 'Confirmar' : 'Continuar'}
                </Button>
              </Grid.Col>
            </Grid.Row>
          )
        }

      </SidebarContainer>
    </Fragment>
  )
}

CreateTransferComponentForm.propTypes = {
  handleClose: PropTypes.func
}

export default CreateTransferComponentForm
