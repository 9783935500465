import React, { Fragment, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import t from 'tcomb-form'

import UISupportInfo from 'src/UIComponents/Customize/SupportInfo'
import * as customActions from 'src/Actions/custom'
import formValidators from 'src/Utils/formValidators'
import Disclaimer from 'src/UIComponents/Disclaimer/Disclaimer'
import { isEqual } from 'lodash'

const SupportInfo = ({ disabled, hideButtonEdit, handleEdit, history }) => {
  const dispatch = useDispatch()
  const { custom, marketplace } = useSelector(state => ({
    custom: state.custom,
    marketplace: state.marketplace
  }))

  const INITIAL_FORM_VALUES = {
    email: custom.supportEmail,
    phoneNumber: custom.supportPhone,
    site: custom.site,
    address: custom.address
  }

  let forcePageLeave = false

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [disclaimerNextPath, setDisclaimerNextPath] = useState()
  const [showValidation, setShowValidation] = useState(false)

  const struct = t.struct({
    email: formValidators.Email,
    phoneNumber: formValidators.Phone
  })

  useEffect(() =>
    handleEdit(null),
  [ custom.updateSupportDataSuccess ]
  )

  useEffect(() => {
    if (disclaimerNextPath) {
      history.push(disclaimerNextPath)
    }
  }, [formValues])

  const handleRouteLeave = (location) => {
    if (isEqual(INITIAL_FORM_VALUES, formValues) || forcePageLeave) {
      return true
    }

    setDisclaimerNextPath(location.pathname)
    setShowDisclaimer(true)
    return false
  }

  const handleConfirmSubmitForm = () => {
    setShowDisclaimer(false)
    if (!t.validate(formValues, struct).isValid()) {
      setShowValidation(true)
    } else {
      dispatch(customActions.updateSupportData(marketplace.id, {
        support_data: {
          email: formValues.email,
          phone_number: formValues.phoneNumber
        }
      }))
    }
  }

  const handleSubmitForm = () => {
    const isFormValid = t.validate(formValues, struct).isValid()
    setShowDisclaimer(false)
    if (!isFormValid) {
      setShowValidation(true)
    } else {
      setShowDisclaimer(true)
    }
  }

  const handleOnChange = (values) => {
    setShowValidation(false)
    setFormValues(values)
  }

  const handleCancel = () => {
    setFormValues(INITIAL_FORM_VALUES)
    setShowDisclaimer(false)
    handleEdit(null)
  }

  // const handleDisclaimerCancel = () => {
  //   forcePageLeave = true
  //   setShowDisclaimer(false)
  //   history.push(disclaimerNextPath)
  // }

  return (
    <Fragment>
      <Prompt message={handleRouteLeave} />
      <UISupportInfo
        custom={custom}
        disabled={disabled}
        hideButtonEdit={hideButtonEdit}
        handleEdit={handleEdit}
        handleOnChange={handleOnChange}
        formValues={formValues}
        handleSubmit={handleSubmitForm}
        handleCancel={handleCancel}
        showValidation={showValidation}
        struct={struct}
      />
      {
        showDisclaimer &&
        <Disclaimer
          handleSubmit={handleConfirmSubmitForm}
          handleCancel={handleCancel}
        >
          Você alterou os <strong>Dados do Suporte</strong>. Deseja salvar as alterações?
        </Disclaimer>
      }
    </Fragment>
  )
}

SupportInfo.propTypes = {
  disabled: PropTypes.bool,
  handleEdit: PropTypes.func,
  hideButtonEdit: PropTypes.bool,
  history: PropTypes.object
}

export default SupportInfo
